/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

// import brandLogo from "../images/brandLogo.png"
import Footer from "../components/Footer"
import TopNavBar from "./TopNavBar"

import "../styles/index.scss"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteConfig {
        site {
          siteMetadata {
            title
          }
        }
        file(relativePath: { eq: "brandLogo.png" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fixed(width: 125) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        footerImage: file(relativePath: { eq: "LogoFooter.png" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}

    render={data => (
      <>
        <TopNavBar
          siteTitle={data.site.siteMetadata.title}
          brandLogo={data.file.childImageSharp.fixed}
        />
        {children}
        <Footer footerImage={data.footerImage.childImageSharp.fluid}/>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
