import React from "react"
import { Container } from "reactstrap"
import Img from "gatsby-image"

const Footer = props => (
  <footer className="Footer bg-dark py-5">
    <Container>
      <div className="text-center">
        <Img
          alt="Auditores y Asesores | Auditoría y Contabilidad en El Salvador"
          style={{ maxWidth: "300px", margin: "0 auto" }}
          fluid={props.footerImage}
        />
      </div>
      <div className="d-flex justify-content-between py-5">
        <a href="/#aboutUs">Acerca De</a>
        <a href="/#ourServices">Servicios</a>
        <a href="/#ourProcess">Proceso</a>
        <a href="/#contactUs">Contáctanos</a>
      </div>
      <div className="text-center">
        © {new Date().getFullYear()}, Todos los derechos reservados
        {` `}
        <a href="https://www.auditoresyasesores.com.sv">
          Auditores y Asesores S.A. de C.V.
        </a>
      </div>
    </Container>
  </footer>
)

export default Footer
