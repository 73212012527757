import React from "react"
import Img from "gatsby-image"

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

class TopNavBar extends React.Component {
  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.state = {
      isOpen: false,
    }
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }
  render() {
    return (
      <header className="TopNavBar">
        <Navbar color="faded" dark expand="md">
          <NavbarBrand href="/">
            <Img fixed={this.props.brandLogo} alt={this.props.siteTitle} title={this.props.siteTitle} />
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem className="bg-dark">
                <NavLink href="/#aboutUs">Acerca De</NavLink>
              </NavItem>
              <UncontrolledDropdown nav inNavbar className="bg-dark">
                <DropdownToggle nav caret>
                  Servicios
                </DropdownToggle>
                <DropdownMenu right className="bg-dark">
                  <DropdownItem>
                    <NavLink href="/#auditoriaFinanciera" className="bg-dark">
                      Auditoría Financiera
                    </NavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <NavLink href="/#auditoriaFiscal" className="bg-dark">
                      Auditoría Fiscal
                    </NavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <NavLink
                      href="/#auditoriasEspcialesARubrosEspecificos"
                      className="bg-dark"
                    >
                      Auditorías Especiales a Rubros Específicos
                    </NavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <NavLink href="/#consultoria" className="bg-dark">
                      Consultoría
                    </NavLink>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <NavItem className="bg-dark">
                <NavLink href="/#ourProcess">Proceso</NavLink>
              </NavItem>
              <NavItem className="bg-dark">
                <NavLink href="/#contactUs">Contáctanos</NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </header>
    )
  }
}

export default TopNavBar
